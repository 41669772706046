<template>
  <div>
    <Header :title="isEdit ? '修改活动' : '新增活动'" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
              :wrapperCol="{span: 14, xxl: 13 }" @finish="onSubmit">

        <a-form-item label="活动名称" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input v-model:value="modelRef.name" placeholder="请输入活动名称"></a-input>
        </a-form-item>

		<a-form-item label="活动简介" name="description" :rules="[{required: true, message: '必填项不允许为空'}]">
		  <a-input v-model:value="modelRef.description" placeholder="请输入活动简介"></a-input>
		</a-form-item>

        <a-form-item label="活动封面" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：690 : 260">
          <a-upload
              v-model:file-list="fileList"
              list-type="picture-card"
              action="/admin/ajaxUpload.do"
              accept="image/*"
              @change="handleChange"
          >
            <div v-if="fileList.length < 1">
              <Icon icon="PlusOutlined"></Icon>
              <div style="margin-top: 8px">Upload</div>
            </div>
          </a-upload>
        </a-form-item>

		<a-form-item label="活动类型" name="type" :rules="[{required: true, message: '必选项不允许为空'}]">
			<a-radio-group v-model:value="modelRef.type">
				<a-radio :value="1">图文</a-radio>
				<a-radio :value="2">外部链接</a-radio>
			</a-radio-group>
		</a-form-item>

        <a-form-item v-if="modelRef.type === 2" label="外部链接" name="skipUrl" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input v-model:value="modelRef.skipUrl" placeholder="请输入外部链接"></a-input>
        </a-form-item>
		
		<a-form-item v-if="modelRef.type === 1" label="活动详情" name="detail" :rules="[{required: true, message: '必填项不允许为空'}]">
			<Editer v-model:value="modelRef.detail"></Editer>
		</a-form-item>

        <a-form-item label="活动时间" name="time" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-range-picker show-time v-model:value="modelRef.time"></a-range-picker>
        </a-form-item>

        <a-form-item name="memberLevelId" label="可参与会员等级" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-select
            v-model:value="modelRef.memberLevelId"
            mode="multiple"
            placeholder="请选择会员等级">
            <a-select-option v-for="item in lvList" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
          <a-input v-model:value="modelRef.sort" placeholder="请输入优先级"></a-input>
        </a-form-item>

        <!-- <a-form-item label="一卡通提前展示" name="isUnionCardStatus">
          <a-radio-group v-model:value="modelRef.isUnionCardStatus" @change="this.modelRef.unionCardEndTime = null">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="modelRef.isUnionCardStatus === 1" label="提前展示时间" name="unionCardEndTime" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-date-picker show-time placeholder="请选择提前展示时间" v-model:value="modelRef.unionCardEndTime" />
        </a-form-item> -->

		<a-form-item label="活动列表是否展示" name="isListShow">
			<a-radio-group v-model:value="modelRef.isListShow">
				<a-radio :value="1">是</a-radio>
				<a-radio :value="0">否</a-radio>
			</a-radio-group>
		</a-form-item>

        <a-form-item label="是否启用" name="isDisabled">
          <a-switch v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
        </a-form-item>

        <a-row>
          <a-col :span="24" style="margin-top: 20px;text-align: center;">
            <a-button type="primary" html-type="submit">提交</a-button>
            <a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
          </a-col>
        </a-row>

      </a-form>
    </a-spin>
  </div>
</template>

<script>
import Header from '@/components/header/header.vue';
import { Icon } from '@/components/icon/icon.js';
import Editer from '@/components/editor/editor.vue';
import {
  getPlatformActivityDetail,
  savePlatformActivity,
  updatePlatformActivity
} from "@/service/modules/operation";
import {getMemberLevelAllList} from "@/service/modules/member";
export default {
  components: {
    Header,
	Editer,
    Icon,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true
    },
    id: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      loading: false,
      fileList: [],
      modelRef: {
		type: 1,
		isListShow: 1,
        isUnionCardStatus: 1,
        isDisabled: true,
      },
      lvList: [],
    }
  },
  created() {
    this.getMemberLevelAllList();
    if(this.id) {
      this.getData();
    }
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getPlatformActivityDetail({
          id: this.id
        })
        this.loading = false;
        if(ret.code === 200) {
          ret.data.isDisabled = ret.data.isDisabled ? false : true;

          this.modelRef = ret.data;
          if(ret.data.endTime) {
            this.modelRef.endTime = this.moment(ret.data.endTime * 1000);
          } else {
            this.modelRef.endTime = undefined;
          }
          // if(ret.data.unionCardEndTime) {
          //   this.modelRef.unionCardEndTime = this.moment(ret.data.unionCardEndTime * 1000);
          // } else {
          //   this.modelRef.unionCardEndTime = undefined;
          // }

          this.modelRef.memberLevelId = this.modelRef.memberLevelId.split(',');
          // 排除被禁用的会员等级
          this.checkLv();
		  
		  if(ret.data.imageUrl){
		    this.fileList = ret.data.imageUrl.split(',').map(item=>{
		      return {
		        url: item,
		      }
		    })
		  }
        }
      } catch(e) {
        this.loading = false;
      }
    },
    // 获取全部会员等级
    async getMemberLevelAllList() {
      this.loading = true;
      try {
        let ret = await getMemberLevelAllList({});
        this.loading = false;
        if(ret.code === 200) {
          this.lvList = ret.data;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    // 排除被禁用的会员等级
    checkLv() {
      if(this.lvList.length && this.modelRef.memberLevelId.length !== 0) {
        let lvList = [];
        for(let i = 0; i < this.modelRef.memberLevelId.length; i++) {
          for(let j = 0; j < this.lvList.length; j++) {
            if(this.modelRef.memberLevelId[i] == this.lvList[j].id) {
              lvList.push(this.lvList[j].id)
            }
          }
        }
        this.modelRef.memberLevelId = lvList;
      };
    },
    async onSubmit() {
		let imgList = [];
		this.fileList.forEach(item=>{
			if (item.url) {
				imgList.push(item.url);
			}
		});
      if(imgList.length === 0) {
        this.$message.error('请上传活动封面！');
        return;
      }
      let postData = this.$deepClone(this.modelRef);
      postData.isDisabled = postData.isDisabled ? 0 : 1;
	  if (postData.time && postData.time.length) {
		 postData.startTime = postData.time[0].unix();
		 postData.endTime = postData.time[1].unix();
	  }
	  delete postData.time;
      postData.imageUrl = imgList.join(',');
      postData.memberLevelId = postData.memberLevelId.join(',');
      this.loading = true;
      try {
        let ret;
        if(this.isEdit) {
          postData.id = this.id;
          ret = await updatePlatformActivity(postData);
        } else {
          ret = await savePlatformActivity(postData);
        }
        this.loading = false;
        if(ret.code === 200) {
          this.$message.success('操作成功');
          this.onBack(true);
        }
      } catch(e) {
        this.loading = false;
      }
    },
    handleChange(info) {
      if (info.file.status === 'done') {
        //console.log(info.file);
        this.fileList = info.fileList;
        const $fileList = [...this.fileList];
        this.fileList = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
  }
}
</script>

<style scoped>

</style>